<template>
  <a-row :gutter="24" type="flex">

    <!-- Projects Table Column -->
    <a-col :span="24" class="mb-24">

      <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
        <template #title>
          <a-row type="flex" align="middle">
            <a-col :span="24" :md="12" class="top-left-header">
              <h5 class="font-semibold m-0">Monthly Content<a-spin v-if="confirmLoading"/></h5>
            </a-col>
            <a-col :span="24" :md="12" class="top-right-header">
              <a-button @click="uploadNewContentModal = true">Add New</a-button>
              <!--              <a-range-picker v-model:dateRange="dateRange" format="YYYY-MM-DD" @change="onDateChange"/>-->
              <a-radio-group v-model="projectHeaderBtns" size="small" @change="onStatusChange">
                <a-radio-button value="day_born_month">Day Born Monthly</a-radio-button>
                <a-radio-button value="tarot_card">Tarot Card Monthly</a-radio-button>
              </a-radio-group>
            </a-col>
          </a-row>
        </template>
        <a-table :columns="columnData"
                 :data-source="featureContentList"
                 :pagination="false">

          <template slot="name" slot-scope="text">
            <a>{{ text }}</a>
          </template>

          <template slot="uploadBtn" slot-scope="row">
            <div>
              <a-button type="link" :data-id="row.key">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                     @click="showUpdateModal(row.details)">
                  <path class="fill-gray-7"
                        d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"/>
                  <path class="fill-gray-7"
                        d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"/>
                </svg>
                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"
                     @click="showDetailsModal(row.details)">
                  <path
                      d="M22 24h-20v-24h14l6 6v18zm-7-23h-12v22h18v-16h-6v-6zm3 15v1h-12v-1h12zm0-3v1h-12v-1h12zm0-3v1h-12v-1h12zm-2-4h4.586l-4.586-4.586v4.586z"/>
                </svg>
              </a-button>
            </div>
          </template>
        </a-table>

        <div class="pagination">
          <a-pagination
              v-model:current="currentPage"
              :total="pagination.total"
              :page-size="20"
              :hideOnSinglePage="true"
              @change="onPageChange"
          />
        </div>
      </a-card>

    </a-col>
    <!-- / Projects Table Column -->

    <!--    Upload New Content-->
    <a-modal
        v-model:uploadNewContentModal="uploadNewContentModal"
        title="Monthly Feature Content Upload"
        :confirm-loading="confirmLoading"
        @ok="closeUploadNewContentModal"
    >
      <div class="new-upload-data">
        <a-radio-group v-model="uploadSelectedType" size="small" class="mb-5" @change="changeUploadSelectedType">
          <a-radio-button value="day_born_month">Day Born Monthly</a-radio-button>
          <a-radio-button value="tarot_card">Tarot Card Monthly</a-radio-button>
        </a-radio-group>
        <a-select
            v-model:selectedTypeName="selectedTypeName"
            @change="selectedOptionChange"
            v-if="uploadSelectedType === 'day_born_month'"
        >
          <a-select-option v-for="week in weekName"
                           :key="week"
                           :value="week">
            {{ week }}
          </a-select-option>
        </a-select>
        <a-select
            v-model:selectedTypeName="selectedTypeName"
            @change="selectedOptionChange"
            v-else
        >
          <a-select-option v-for="card in tarotCardList"
                           :key="card.slug"
                           :value="card.slug">
            {{ card.name }}
          </a-select-option>
        </a-select>
        <div class="year-month-section">
          <div class="year-list">
            <label>Year</label>
            <a-select
                v-model:selectedYear="selectedYear"
            >
              <a-select-option v-for="year in years" :key="year" :value="year">
                {{ year }}
              </a-select-option>
            </a-select>
          </div>
          <div class="month-list">
            <label>Month</label>
            <a-select style="width: 116px;"
                      v-model:selectedMonth="selectedMonth"
            >
              <a-select-option v-for="month in months" :key="month.month" :value="month.month">
                {{ month.name }}
              </a-select-option>
            </a-select>
          </div>

        </div>
        <div>
          <label>Upload {{ uploadSelectedType === 'day_born_month' ? 'video ' : 'Audio' }} File</label>
          <a-input type="file" @change="onFileChange" ref="uploadImage"
                   placeholder="Basic usage"
                   :accept="uploadSelectedType === 'day_born_month' ? 'video/mp4,video/x-m4v,video/*' : '.mp3,audio/*'"/>
        </div>
        <div v-if="errorMsg.length" class="ant-btn-danger mt-5">{{ errorMsg }}</div>
      </div>
    </a-modal>

    <!-- Update Data-->
    <a-modal
        v-model:updateModal="updateModal"
        title="Feature Content Upload"
        :confirm-loading="confirmLoading"
        @ok="closeUpdateModal"
    >
      <div class="upload-data">
        <div>ID: {{ selectedFeatureContentId }}</div>
        <div>File Link: {{ contentFileLink }}</div>
        <div>
          <label>Update {{ selectedFeatureType === 'day_born_month' ? 'video ' : 'Audio' }} File</label>
          <a-input type="file"
                   ref="uploadImage"
                   @change="onFileChange"
                   placeholder="Basic usage"
                   :accept="selectedFeatureType === 'day_born_month' ? 'video/mp4,video/x-m4v,video/*' : '.mp3,audio/*'"/>
        </div>
        <div class="ant-btn-danger mt-5" v-if="errorMsg.length">{{ errorMsg }}</div>
      </div>
    </a-modal>

    <!--    Monthly Content Details-->
    <a-modal
        v-model:detailsModal="detailsModal"
        title="Monthly Content Details"
        @ok="closeDetailsModal"
        @cancel="closeDetailsModal"
    >
      <div class="content-details">
        <div>ID: <span>{{ contentDetails.id }}</span></div>
        <div>Type Name: <span>{{
            contentDetails.type_name === 'day_born_month' ? 'Day Born Monthly' : 'Tarot Card'
          }}</span></div>
        <div>Card name: <span>{{
            contentDetails.type_name === 'day_born_month' ? contentDetails.day_name : contentDetails.tarot_card_name
          }}</span></div>
        <div>Year Month: <span>{{ contentDetails.year_month }}</span></div>
        <div>Content File : <span>{{ contentDetails.content_file }}</span></div>
        <div v-if="Object.keys(contentDetails).length">
          <video width="320" height="240" controls v-if="contentDetails.type_name === 'day_born_month'">
            <source :src="contentDetails.content_file" type="video/mp4">
            Your browser does not support the video tag.
          </video>
          <audio controls v-else>
            <source :src="contentDetails.content_file" type="audio/wav">
            Your browser does not support the audio element.
          </audio>
        </div>
      </div>
    </a-modal>

  </a-row>
</template>

<script>
import {sendRequest} from '@/http/axios.method';
import moment from "moment";

const COLUMN_DATA = [
  {
    title: 'ID',
    dataIndex: 'id',
    scopedSlots: {customRender: 'id'},
    width: 90,
  },
  {
    title: 'Type Name',
    dataIndex: 'type_name',
    width: 200,
  },
  {
    title: 'Card Name',
    dataIndex: 'card_name',
    class: 'font-semibold text-muted',
  },
  {
    title: 'File Type',
    dataIndex: 'file_type',
    class: 'font-semibold text-muted',
  },
  {
    title: 'Year Month',
    dataIndex: 'year_month',
    class: 'font-semibold text-muted',
  },
  {
    title: 'Content',
    dataIndex: 'content_file',
    class: 'font-semibold text-muted',
  },

  {
    title: '',
    scopedSlots: {customRender: 'uploadBtn'},
    width: 200,
  },
];
const WEEK_NAME = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday'
];

const MONTHS = [
  {
    name: 'January',
    month: '01'
  },
  {
    name: 'February',
    month: '02'
  },
  {
    name: 'March',
    month: '03'
  },
  {
    name: 'April',
    month: '04'
  },
  {
    name: 'May',
    month: '05'
  },
  {
    name: 'June',
    month: '06'
  },
  {
    name: 'July',
    month: '07'
  },
  {
    name: 'August',
    month: '08'
  },
  {
    name: 'September',
    month: '09'
  },
  {
    name: 'October',
    month: '10'
  },
  {
    name: 'November',
    month: '11'
  },
  {
    name: 'December',
    month: '12'
  },

];

const TAROT_CARD_LIST = [
  {
    name: 'The Fool',
    slug: 'the_fool',
  },
  {
    name: 'The Magician',
    slug: 'the_magician',
  },
  {
    name: 'The High Priestess',
    slug: 'the_high_priestess',
  },
  {
    name: 'The Empress',
    slug: 'the_empress',
  },
  {
    name: 'The Emperor',
    slug: 'the_emperor',
  },
  {
    name: 'The Hierophant',
    slug: 'the_hierophant',
  },
  {
    name: 'The Lovers',
    slug: 'the_lovers',
  },
  {
    name: 'The Chariot',
    slug: 'the_chariot',
  },
  {
    name: 'Strength',
    slug: 'strength',
  },
  {
    name: 'The Hermit',
    slug: 'the_hermit',
  },
  {
    name: 'Wheel Of Fortune',
    slug: 'wheel_of_fortune',
  },
  {
    name: 'The Hanged Man',
    slug: 'the_hanged_man',
  },
  {
    name: 'Death',
    slug: 'death',
  },
  {
    name: 'Temperance',
    slug: 'temperance',
  },
  {
    name: 'The Devil',
    slug: 'the_devil',
  },
  {
    name: 'The Tower',
    slug: 'the_tower',
  },
  {
    name: 'The Star',
    slug: 'the_star',
  },
  {
    name: 'The Moon',
    slug: 'the_moon',
  },
  {
    name: 'The Sun',
    slug: 'the_sun',
  },
  {
    name: 'Judgment',
    slug: 'judgment',
  },
  {
    name: 'The World',
    slug: 'the_world',
  },
]

export default {
  name: "MonthlyContent",
  data() {
    return {
      config: {
        'Authorization': 'Bearer ' + this.$store.state.authUser.token
      },
      columnData: COLUMN_DATA,
      featureMonthlyContents: [],
      currentPage: 1,
      pagination: {},
      projectHeaderBtns: 'day_born_month',
      modalOpen: false,


      // Upload New Content Property
      weekName: WEEK_NAME,
      tarotCardList: TAROT_CARD_LIST,
      uploadSelectedType: 'day_born_month',
      uploadNewContentModal: false,
      selectedDate: '',
      selectedTypeName: 'sunday',
      fileContent: '',
      confirmLoading: false,
      months: MONTHS,
      years: [
        moment().format('YYYY'),
        moment().add(1, 'years').format('YYYY'),
        moment().add(2, 'years').format('YYYY')
      ],
      selectedYear: moment().format('YYYY'),
      selectedMonth: moment().format('MM'),

      //Update Monthly feature content
      selectedFeatureContentId: '',
      selectedFeatureType: '',
      updateModal: false,
      contentFileLink: '',
      detailsModal: false,
      contentDetails: [],
      errorMsg: ''
    }
  },
  created() {
    this.getFeatureMonthlyContents()
  },
  methods: {
    onPageChange() {
      this.getFeatureMonthlyContents()
    },
    onStatusChange() {
      this.getFeatureMonthlyContents()
    },
    getFeatureMonthlyContents() {
      let data = {
        page: this.currentPage,
        type_name: this.projectHeaderBtns
      }
      this.confirmLoading = true
      sendRequest('get', 'admin/monthly-content-list', data, this.config)
          .then(response => {
            this.confirmLoading = false
            if (response.success) {
              let data = response.data.monthly_content
              this.featureMonthlyContents = data.map(function (content, label) {
                return {
                  key: label + 1,
                  id: content.id,
                  type_name: content.type_name === 'day_born_month' ? 'Day Born Monthly' : 'Tarot Card Monthly',
                  card_name: content.type_name === 'day_born_month' ? content.day_name : content.tarot_card_name,
                  file_type: content.media_type,
                  year_month: content.year_month,
                  content_file: content.content_file,
                  details: content
                };
              });
              this.pagination = response.data.meta.pagination
              this.currentPage = this.pagination.current_page
            } else {
              if (response.status_code === 401) {
                this.$store.dispatch('logout');
                this.$router.push('/login');
              }
            }
          })
          .catch(() => {
            this.confirmLoading = false
          })
          .finally(() => {
            this.confirmLoading = false
          })
    },

    onFileChange(e) {
      this.errorMsg = '';
      this.fileContent = e.target.files[0];
    },

    //Upload New Content
    changeUploadSelectedType() {
      this.selectedTypeName = this.uploadSelectedType === 'day_born_month' ? 'sunday' : 'the_fool';
    },
    selectedOptionChange() {
      // console.log('option name', this.selectedTypeName)
    },

    closeUploadNewContentModal() {
      this.confirmLoading = true;
      this.errorMsg = '';
      let formData = new FormData();
      let configHeader = {
        'content-type': 'multipart/form-data',
        'Authorization': 'Bearer ' + this.$store.state.authUser.token
      }
      formData.append('type_name', this.uploadSelectedType);
      if (this.uploadSelectedType === 'day_born_month') {
        formData.append('day_name', this.selectedTypeName);
      } else {
        formData.append('tarot_card_name', this.selectedTypeName);
      }
      formData.append('year_month', this.selectedYear + '-' + this.selectedMonth);
      formData.append('content_file', this.fileContent);

      sendRequest('post', 'admin/monthly-contents', formData, configHeader)
          .then(response => {
            if (response.success) {
              this.confirmLoading = false;
              this.fileContent = '';
              this.$refs.uploadImage.value = null;
              this.uploadSelectedType = 'day_born_month';
              this.selectedTypeName = 'sunday';
              this.selectedYear = moment().format('YYYY');
              this.selectedMonth = moment().format('MM');
              this.uploadNewContentModal = false;
              this.getFeatureMonthlyContents()
            } else {
              if (response.status_code === 401) {
                this.$store.dispatch('logout');
                this.$router.push('/login');
              }
            }

          })
          .catch(() => {
            this.confirmLoading = false;
          })
          .finally(() => {
            this.confirmLoading = false;
          })
    },

    // Update Selected Data
    showUpdateModal(content) {
      this.updateModal = true;
      this.selectedFeatureContentId = content.id;
      this.selectedFeatureType = content.type_name;
      this.contentFileLink = content.content_file;
    },
    closeUpdateModal() {
      this.confirmLoading = true;
      this.errorMsg = '';
      let formData = new FormData();
      let configHeader = {
        'content-type': 'multipart/form-data',
        'Authorization': 'Bearer ' + this.$store.state.authUser.token
      }
      formData.append('monthly_content_id', this.selectedFeatureContentId);
      formData.append('type_name', this.selectedFeatureType);
      formData.append('content_file', this.fileContent);
      sendRequest('post', 'admin/monthly-content-update', formData, configHeader)
          .then(response => {
            if (response.success) {
              this.confirmLoading = false;
              this.updateModal = false;
              this.selectedFeatureContentId = '';
              this.selectedFeatureType = '';
              this.fileContent = '';
              this.getFeatureMonthlyContents()
            } else {
              if (response.status_code === 401) {
                this.$store.dispatch('logout');
                this.$router.push('/login');
              }
              if (response.status_code === 422) {
                let errors = response.data.errors;
                this.errorMsg = errors.content_file[0];
              }
            }

          })
          .catch(() => {
            this.confirmLoading = false;
          })
          .finally(() => {
            this.confirmLoading = false;
          })
    },

    // Monthly  Content Details
    showDetailsModal(content) {
      this.detailsModal = true;
      this.contentDetails = content;
    },
    closeDetailsModal() {
      this.detailsModal = false;
      this.contentDetails = [];
    }
  },
  computed: {
    featureContentList: function () {
      return this.featureMonthlyContents;
    },
  }
}
</script>

<style scoped>

</style>
